import React from "react";
import { ButtonOnlyIcon, ButtonWithIcon } from "../ui/Button";
import { FaSearch } from "react-icons/fa";

export const FormSearch = ({
  onSubmitForm,
  type,
  value,
  onChangeItem,
  placeholder,
}) => {
  return (
    <form onSubmit={onSubmitForm}>
      <div className="flex items-center gap-4">
        <input
          type={type}
          value={value}
          onChange={onChangeItem}
          placeholder={placeholder}
          className="border px-4 py-1 text-sm rounded-md border-gray-500 focus:border-gray-900"
        />
        <ButtonOnlyIcon
          type="submit"
          icon={FaSearch}
          text="Search"
          className="bg-gray-600  hover:bg-gray-500 focus:outline-none focus:ring focus:ring-gray-300 text-white"
        />
      </div>
    </form>
  );
};
