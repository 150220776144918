import React from "react";

export const Textfield = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  className,
}) => {
  return (
    <div className="mb-2 flex flex-col">
      <label htmlFor="name" className="text-gray-700 font-semibold my-1">
        {label}
      </label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={
          `border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline` +
          className
        }
      />
    </div>
  );
};
