import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaSave, FaTools } from "react-icons/fa";
import { ContainerForm } from "../../components/ui/Container";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const EditDaging = () => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await axios.get(`${apiUrl}/daging/${id}`);
    setTitle(response.data.title);
    setPrice(response.data.price);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateServices = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("price", price);
    try {
      await axios.put(`${apiUrl}/daging/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/daging");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/daging"
        pathName="Daging Segar"
        pageName="Edit Daging Segar"
      />
      <Form onSubmit={EditDaging} title="Edit Data Daging Segar">
        <Textfield
          type="text"
          label="Data daging segar"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Masukan daging segar"
        />

        <Textfield
          type="text"
          label="Harga"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Masukan harga"
        />

        <PreviewImage
          title="Pratinjau Gambar:"
          preview={preview}
          src={preview}
          srcNone={noImage}
        />
        <FileImage title="Gambar" onChange={loadImage} />

        <ButtonWithIcon type="submit" icon={FaSave} text="Simpan Data " />
      </Form>
    </ContainerForm>
  );
};

export default EditDaging;
