import React from "react";

export const Form = ({ onSubmit, children, className, title }) => {
  return (
    <div className="border rounded-md">
      <div className="flex py-4 px-2 flex-col items-start bg-green-100">
        <p className="text-lg text-gray-900">{title}</p>
        <hr />
      </div>

      <form onSubmit={onSubmit}>
        <div
          className={`grid grid-cols-1 lg:grid-cols-2 gap-2 p-6 rounded-md ${className}`}
        >
          {children}
        </div>
      </form>
    </div>
  );
};
