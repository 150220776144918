import React from "react";
import { Heading } from "../ui/Heading";
import { Link } from "react-router-dom";
import { ButtonWithIcon } from "../ui/Button";
import { FaPlus, FaSquare } from "react-icons/fa";

export const TitleList = ({ title, icon, text, rows, className, linkTo }) => {
  return (
    <div className="flex justify-between py-2">
      <Heading
        title={title}
        datarows={rows}
        icon={icon}
        color="text-orange-500"
      />
      <Link to={linkTo}>
        <ButtonWithIcon icon={FaPlus} text={text} className={className} />
      </Link>
    </div>
  );
};
