import React from "react";
export const Chip = ({ title, className }) => {
  const defaultClassName = "font-semibold rounded-xl px-6 py-1";

  return (
    <span
      className={`${defaultClassName} ${
        className ? className : "text-green-500 bg-green-200"
      }`}
    >
      {title}
    </span>
  );
};
