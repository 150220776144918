import React from "react";
import { useState } from "react";
import axios from "axios";
import LogoCompany from "../../assets/logohbs.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const apiUrl = process.env.REACT_APP_API;

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${apiUrl}/user/login`, {
        username: username,
        password: password,
      });

      // Jika login berhasil, simpan token di localStorage
      const token = response.data.token;
      localStorage.setItem("token", token);

      // Redirect atau lakukan tindakan sesuai dengan hasil login
      // contohnya, mengarahkan pengguna ke halaman dashboard
      window.location.href = "/";
    } catch (error) {
      console.error("Login failed:", error);
      // Tangani kesalahan atau tampilkan pesan kesalahan ke pengguna
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-orange-300">
      <div className="w-full max-w-md space-y-2 p-6 bg-white rounded-lg shadow-lg">
        <img
          src={LogoCompany}
          className="w-48 text-center"
          alt="Preview Image"
        />
        {/* <h1 className="text-2xl font-semibold ">Login</h1> */}
        <h1 className="text-lg font-semibold">HBS Livestock</h1>
        <div>
          <label
            htmlFor="username"
            className="text-sm font-medium text-gray-600"
          >
            Username
          </label>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-2 mt-1 border rounded-lg"
          />
        </div>
        <div>
          <label
            htmlFor="password"
            className="text-sm font-medium text-gray-600"
          >
            Password
          </label>
          <input
            className="w-full p-2 mt-1 border rounded-lg"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button
          className="w-full p-2 mt-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
          type="submit"
          onClick={handleLogin}
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default Login;
