import Sidebar from "./Sidebar";

function SidebarLayout({ children }) {
  return (
    <div className="flex gap-5">
      <Sidebar />
      <main className="max-w-6xl flex-1 mx-auto p-2">{children}</main>
    </div>
  );
}

export default SidebarLayout;
