import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Chip } from "../../components/ui/Chip";
import { Container } from "../../components/ui/Container";

const ViewNews = () => {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [newsslug, setNewsSlug] = useState("");
  const [datetime, setDatetime] = useState(new Date());
  const [edition, setEdition] = useState("");
  const [year, setYear] = useState("");
  const [image, setImage] = useState("");
  const [type, setType] = useState();
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const { id } = useParams();

  useEffect(() => {
    getNewsById();
  }, []);

  const getNewsById = async () => {
    const response = await axios.get(`${apiUrl}/news/${id}`);
    setHeadline(response.data.headline);
    setDescription(response.data.description);
    setYear(response.data.year);
    setEdition(response.data.edition);
    setNewsSlug(response.data.newsslug);
    setDatetime(response.data.datetime);
    setType(response.data.type);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <Container>
      <div className="ck-content">
        <HeadingTitleForm title="News Detail" />
        <div className="flex items-center justify-center">
          <img src={preview} className="object-cover w-full h-96" />
        </div>

        <div className="flex gap-10">
          <div className="flex flex-col gap-5">
            <p className="text-2xl font-semibold pt-4">{headline}</p>
            <div className="flex gap-4 ">
              <p>
                {type ? (
                  <Chip title="Aktif" />
                ) : (
                  <Chip title="Tidak" className="bg-red-200 text-red-500" />
                )}
              </p>

              <Chip title={formatClosing(datetime)} className="bg-green-700" />
            </div>

            <p
              className="editor"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewNews;
