import React from "react";
import { FormSearch } from "./FormSearch";
import { Option } from "../ui/Option";

export const FilterList = ({
  onSubmitForm,
  onChangeItem,
  value,
  placeholder,
  options,
  onClickButtonOption,
  onClickButtonTrigers,
  isOpen,
  limit,
}) => {
  return (
    <div className="flex gap-2 justify-between">
      <FormSearch
        onSubmitForm={onSubmitForm}
        type="text"
        value={value}
        onChangeItem={onChangeItem}
        placeholder={placeholder}
      />
      <Option
        title="Menampilkan"
        options={options}
        onClickButtonOption={onClickButtonOption}
        onClickButtonTrigers={onClickButtonTrigers}
        isOpen={isOpen}
        limit={limit}
      />
    </div>
  );
};
