import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaEdit, FaEye, FaNewspaper } from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";

import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import { Chip } from "../../components/ui/Chip";
import DeleteButton from "../../components/BigComponent/DeleteButton";
import { FilterList } from "../../components/BigComponent/FilterList";
import { TitleList } from "../../components/BigComponent/TitleList";

const ListNews = () => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getNews();
  }, [page, keyword, limit]);

  const getNews = async () => {
    const response = await axios.get(
      `${apiUrl}/news?title=${keyword}&page=${page}&limit=${limit}`
    );

    setNews(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = news.filter((item) => item.id !== id);
    setNews(updatedData);
  };
  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <Container>
      <TitleList
        title="Data Article"
        icon={FaNewspaper}
        text="Tambah"
        rows={news.length}
        linkTo="/article/add"
      />

      <div className="border rounded-lg pb-4">
        <div className="p-6">
          <FilterList
            onSubmitForm={searchData}
            value={query}
            onChangeItem={(e) => setQuery(e.target.value)}
            placeholder="Ketik judul article disini "
            options={options}
            onClickButtonOption={handleOptionChange}
            onClickButtonTrigers={toggleDropdown}
            isOpen={isOpen}
            limit={limit}
          />
        </div>

        <hr />
        <Table>
          <TableHead>
            {/* <TableHeader HeaderTitle="Gambar" /> */}
            <TableHeader HeaderTitle="Judul Article" />
            <TableHeader HeaderTitle="Edisi" />
            <TableHeader HeaderTitle="Status" />
            <TableHeader HeaderTitle="Aksi" />
          </TableHead>
          {news.length === 0 ? (
            <TableBody>
              <TableRows>
                <TableCells className="text-red-700 font-bold flex justify-center items-center mx-auto col-span-4">
                  <p> Tidak ada data yang ditemukan</p>
                </TableCells>
              </TableRows>
            </TableBody>
          ) : (
            <TableBody>
              {news.map((item, index) => (
                <TableRows key={item.id}>
                  {/* <TableCells className="text-center"> {index + 1}</TableCells> */}
                  {/* <TableCells>
                    <img src={item.url} alt="Image" className="w-24" />
                  </TableCells> */}
                  <TableCells> {item.headline}</TableCells>

                  <TableCells> {item.year}</TableCells>
                  <TableCells className="text-center">
                    {item.status ? (
                      <Chip title="Aktif" />
                    ) : (
                      <Chip title="Tidak" className="bg-red-200 text-red-500" />
                    )}
                  </TableCells>
                  <TableCells>
                    <div className="flex justify-center items-center gap-1">
                      <Link to={`/article/edit/${item.id}`}>
                        <ButtonOnlyIcon icon={FaEdit} />
                      </Link>

                      {/* <Link to={`/article/view/${item.id}`}>
                        <ButtonOnlyIcon icon={FaEye} />
                      </Link> */}

                      <DeleteButton
                        id={item.id}
                        api="news"
                        name={item.title}
                        title="news"
                        onDelete={handleDeleteButton}
                      />
                    </div>
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          )}
        </Table>
        <div className="flex gap-2 mx-auto justify-between px-6">
          <div className="flex gap-2">
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={handlePageChange}
            />
          </div>
          <RowsPagination rows={rows} page={page} pages={pages} />
        </div>
      </div>
    </Container>
  );
};

export default ListNews;
