import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaNewspaper, FaSave } from "react-icons/fa";
import DateTime from "../../components/ui/DateTime";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContainerForm } from "../../components/ui/Container";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const EditNews = () => {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [newsslug, setNewsSlug] = useState("");
  const [datetime, setDatetime] = useState(new Date());
  const [edition, setEdition] = useState("");
  const [year, setYear] = useState("");
  const [image, setImage] = useState("");
  const [type, setType] = useState();
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getNewsById();
  }, []);

  const getNewsById = async () => {
    const response = await axios.get(`${apiUrl}/news/${id}`);
    setHeadline(response.data.headline);
    setDescription(response.data.description);
    setYear(response.data.year);
    setEdition(response.data.edition);
    setNewsSlug(response.data.newsslug);
    setDatetime(response.data.datetime);
    setType(response.data.type);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateNews = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("headline", headline);
    formData.append("edition", edition);
    formData.append("year", year);
    formData.append("description", description);
    formData.append("datetime", datetime);
    formData.append("newsslug", newsslug);
    formData.append("image", image);
    formData.append("type", type);
    try {
      await axios.put(`${apiUrl}/news/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/article");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = () => {
    setType(!type);
  };

  const handleDateChange = (date) => {
    setDatetime(date);
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/article"
        pathName="Article"
        pageName="Edit Article"
      />
      <Form onSubmit={updateNews} title="Edit Data Article">
        <div className="col-span-2">
          <Textfield
            type="text"
            label="Headline Berita"
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
            placeholder="Masukan Headline berita"
          />
        </div>
        <Textfield
          type="text"
          label="Slug Article"
          value={newsslug}
          onChange={(e) => setNewsSlug(e.target.value)}
          placeholder="Masukan Slug"
        />
        <Textfield
          type="text"
          label="Edisi Article"
          value={edition}
          onChange={(e) => setEdition(e.target.value)}
          placeholder="Contoh Oktober"
        />
        <Textfield
          type="text"
          label="Tahun Article"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          placeholder="Contoh 2023"
        />
        <DateTime
          name="datetime"
          id="datetime"
          selected={new Date(datetime)}
          onChange={handleDateChange}
          label="Tanggal"
        />

        <div>
          <PreviewImage
            title="Pratinjau Gambar:"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Gambar" onChange={loadImage} />
        </div>
        <Checkbox
          type="checkbox"
          name="type"
          title="status"
          checked={type}
          onChange={handleCheckboxChange}
          alert="Apakah Article ini masih aktif?"
          positif="Yes"
          negative="No"
        />
        <div className="col-span-2">
          <p className="font-semibold">Detail Informasi service</p>
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>
        <ButtonWithIcon type="submit" icon={FaSave} text="Simpan Data " />
      </Form>
    </ContainerForm>
  );
};

export default EditNews;
