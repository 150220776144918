import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { FaSave, FaTools } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import { Textarea } from "../../components/ui/Textarea";
import { ContainerForm } from "../../components/ui/Container";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const AddDaging = () => {
  const [kode, setKode] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const saveDaging = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("price", price);
    formData.append("kode", kode);
    try {
      await axios.post(`${apiUrl}/daging`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/daging");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/daging"
        pathName="Daging"
        pageName="Tambah Daging"
      />
      <Form onSubmit={saveDaging} title="Tambah Data Dagiong Segar">
        <Textfield
          type="text"
          label="Data Sapi Segar"
          value={kode}
          onChange={(e) => setKode(e.target.value)}
          placeholder="Masukan Kode"
        />
        <Textfield
          type="text"
          label="Data Sapi Segar"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Masukan judul daging segar"
        />

        <Textfield
          type="text"
          label="Harga"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Masukan harga"
        />

        <div>
          <PreviewImage
            title="Pratinjau Gambar:"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Gambar" onChange={loadImage} />
        </div>

        <ButtonWithIcon type="submit" icon={FaSave} text="Simpan Data " />
      </Form>
    </ContainerForm>
  );
};

export default AddDaging;
