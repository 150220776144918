import React, { useState, useEffect } from "react";
import axios from "axios";
import { Heading } from "../../components/ui/Heading";
import LogoCompany from "../../assets/logo.png";
import { ButtonOnlyIcon } from "../../components/ui/Button";
import {
  FaAppStore,
  FaCarAlt,
  FaEdit,
  FaEye,
  FaFileDownload,
  FaNewspaper,
  FaPeopleCarry,
  FaPlusSquare,
  FaServicestack,
  FaTools,
  FaUser,
  FaUserCheck,
} from "react-icons/fa";
import Card from "../../components/ui/Card";

const Box = ({ title, subtitle, count, icon }) => {
  return (
    <div className="bg-blue-300 p-4 text-center text-blue-900 rounded-xl">
      <h2 className="text-lg font-bold mb-2">{title}</h2>
      <p className="text-sm mb-4">{subtitle}</p>
      <div className="flex justify-center items-center h-24 w-24 rounded-full bg-blue-700 text-white mx-auto mb-4">
        <span className="text-4xl font-bold">{count}</span>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [dashboardData, setDashboarData] = useState([]);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = async () => {
    const response = await axios.get(`${apiUrl}/dashboard`);
    setDashboarData(response.data.data);
  };

  const showNews = dashboardData.filter((item) => item.title === "Daging");
  const guestCount = dashboardData.filter((item) => item.title === "Sapi");
  const serviceCount = dashboardData.filter(
    (item) => item.title === "Frozen Food"
  );
  const gallerCount = dashboardData.filter((item) => item.title === "News");

  return (
    <div className="py-8 px-4">
      <div className="flex gap-2 rounded-xl">
        <div className="flex flex-col gap-1">
          <h1 className="font-bold text-3xl text-green-500">
            Selamat Datang Admin
          </h1>
          <h1>Website Company Profile</h1>
        </div>
        <hr />
      </div>

      <h1 className="text-2xl font-bold my-6">Dashboard</h1>
      <div className="grid grid-cols-2 place-items-center gap-4">
        {guestCount.map((item, index) => (
          <Card
            key={index}
            icon={FaUser}
            className="border border-green-500 bg-green-50"
            color="text-green-600"
            title={item.title}
            count={item.count}
          />
        ))}

        {/* {authoritiesCount.map((item, index) => (
          <Card
            key={index}
            icon={FaUserCheck}
            className="border border-purple-500 bg-purple-50"
            color="text-purple-600"
            title={item.title}
            count={item.count}
          />
        ))} */}
        {serviceCount.map((item, index) => (
          <Card
            key={index}
            icon={FaTools}
            className="border border-blue-500 bg-blue-50"
            color="text-blue-600"
            title={item.title}
            count={item.count}
          />
        ))}
        {/* {applicationCount.map((item, index) => (
          <Card
            key={index}
            icon={FaAppStore}
            className="border border-yellow-500 bg-yellow-50"
            color="text-yellow-600"
            title={item.title}
            count={item.count}
          />
        ))} */}
        {showNews.map((item, index) => (
          <Card
            key={index}
            icon={FaNewspaper}
            className="border border-red-500 bg-red-50"
            color="text-red-600"
            title={item.title}
            count={item.count}
          />
        ))}
        {gallerCount.map((item, index) => (
          <Card
            key={index}
            icon={FaPeopleCarry}
            className="border border-orange-500 bg-orange-50"
            color="text-orange-600"
            title={item.title}
            count={item.count}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
