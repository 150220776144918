import { BrowserRouter, Routes, Route } from "react-router-dom";
import SidebarLayout from "./components/Navigation/SidebarLayout";

import ListNews from "./page/news/ListNews";
import AddNews from "./page/news/AddNews";
import ViewNews from "./page/news/ViewNews";
import EditNews from "./page/news/EditNews";

import Login from "./page/user/Login";
import ListUsers from "./page/user/ListUser";
import AddUser from "./page/user/AddUser";
import EditUser from "./page/user/EditUser";
import Dashboard from "./page/dashboard/Dashboard";
import ListDaging from "./page/daging/ListDaging";
import AddDaging from "./page/daging/AddDaging";
import EditDaging from "./page/daging/EditDaging";
import ListSapi from "./page/sapi/ListSapi";
import AddSapi from "./page/sapi/AddSapi";
import EditSapi from "./page/sapi/EditSapi";
import ListFrozenFood from "./page/frozenfood/ListFrozenFood";
import AddFrozenFood from "./page/frozenfood/AddFrozenFood";
import EditFrozenFood from "./page/frozenfood/EditFrozenFood";

function App({ children }) {
  const token = localStorage.getItem("token");
  return (
    <BrowserRouter>
      {token ? (
        <SidebarLayout>
          <Routes>
            <Route path="/" element={<Dashboard />} />

            <Route path="/daging" element={<ListDaging />} />
            <Route path="/daging/add" element={<AddDaging />} />
            <Route path="/daging/edit/:id" element={<EditDaging />} />

            <Route path="/sapi" element={<ListSapi />} />
            <Route path="/sapi/add" element={<AddSapi />} />
            <Route path="/sapi/edit/:id" element={<EditSapi />} />

            <Route path="/frozenfood" element={<ListFrozenFood />} />
            <Route path="/frozenfood/add" element={<AddFrozenFood />} />
            <Route path="/frozenfood/edit/:id" element={<EditFrozenFood />} />

            <Route path="/news" element={<ListNews />} />
            <Route path="/news/add" element={<AddNews />} />
            <Route path="/news/edit/:id" element={<EditNews />} />
            <Route path="/news/view/:id" element={<ViewNews />} />

            <Route path="/user" element={<ListUsers />} />
            <Route path="/user/add" element={<AddUser />} />
            <Route path="/user/edit/:id" element={<EditUser />} />
          </Routes>
        </SidebarLayout>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
    // <div className="flex gap-5">
    //   <Sidebar />
    //   <main className="max-w-5xl flex-1 mx-auto py-4">{children}</main>
    // </div>
  );
}

export default App;
