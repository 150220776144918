import React from "react";
import { motion } from "framer-motion";

export const RowsPagination = ({ rows, page, pages }) => {
  return (
    <motion.span
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      Total Rows: <span className="font-semibold px-2">{rows}</span> Page:
      <span className="font-semibold px-2">{rows ? page - 1 + 1 : 0}</span>
      of <span className="font-semibold px-2">{pages}</span>
    </motion.span>
  );
};
