import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const ButtonWithIcon = ({
  icon: Icon,
  text,
  onClick,
  className,
  type,
}) => {
  const buttonClasses = classNames(
    "flex items-center justify-center px-4 py-2 text-white rounded-md focus:outline-none focus:ring",
    className
      ? className
      : "bg-orange-500  hover:bg-orange-600 focus:ring-orange-300"
  );

  ButtonWithIcon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
  };

  return (
    <button className={buttonClasses} onClick={onClick} type={type}>
      {Icon && <Icon className="w-6 h-6 mr-2" />}
      {text}
    </button>
  );
};

export const ButtonOnlyIcon = ({ icon: Icon, onClick, className, type }) => {
  const buttonClasses = classNames("p-2 text-gray-700 rounded-md", className);
  return (
    <button className={buttonClasses} onClick={onClick} type={type}>
      {Icon && <Icon className="w-4 h-4" />}
    </button>
  );
};
