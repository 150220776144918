import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { FaNewspaper, FaSave } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import DateTime from "../../components/ui/DateTime";
import RichTextEditor from "../../components/ui/RichText";
import { ContainerForm } from "../../components/ui/Container";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const AddSapi = () => {
  const [eartag, setEartag] = useState("");
  const [gender, setGender] = useState("");
  const [feedlot, setFeedlot] = useState("");
  const [weight, setWeight] = useState("");
  const [datein, setDateIn] = useState(new Date());
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const saveGuest = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("eartag", eartag);
    formData.append("gender", gender);
    formData.append("weight", weight);
    formData.append("type", type);
    formData.append("feedlot", feedlot);
    formData.append("datein", datein);
    formData.append("price", price);
    formData.append("image", image);
    try {
      await axios.post(`${apiUrl}/sapi`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });

      navigate("/sapi");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateOfBirth = (datein) => {
    setDateIn(datein);
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/sapi"
        pathName="Sapi Qurban"
        pageName="Tambah Sapi Qurban"
      />
      <Form onSubmit={saveGuest} title="Tambah Data Sapi Qurban">
        <Textfield
          type="text"
          label="Eartag"
          value={eartag}
          onChange={(e) => setEartag(e.target.value)}
          placeholder="Masukan eartag"
        />

        <Textfield
          type="text"
          label="Jenis Kelamin"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          placeholder="Masukan jenis kelamin"
        />
        <Textfield
          type="text"
          label="Feedlot / Kandang"
          value={feedlot}
          onChange={(e) => setFeedlot(e.target.value)}
          placeholder="Masukan Kandang"
        />
        <DateTime
          name="datein"
          id="datein"
          selected={datein}
          onChange={handleDateOfBirth}
          label="Tanggal Pembelian"
        />
        <Textfield
          type="text"
          label="Bobot"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          placeholder="Masukan Bobot"
        />

        <Textfield
          type="text"
          label="Harga"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Masukan harga"
        />

        <Textfield
          type="text"
          label="Jenis Sapi"
          value={type}
          onChange={(e) => setType(e.target.value)}
          placeholder="Masukan Jenis Sapi"
        />

        <PreviewImage
          title="Pratinjau Gambar:"
          preview={preview}
          src={preview}
          srcNone={noImage}
        />
        <FileImage title="Gambar" onChange={loadImage} />

        <ButtonWithIcon type="submit" icon={FaSave} text="Simpan Data " />
      </Form>
    </ContainerForm>
  );
};

export default AddSapi;
